@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700;800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: auto; */
}

body {
  padding-right: 0 !important;
}

.MuiAppBar-root.MuiAppBar-positionFixed {
  padding-right: 0 !important;
}

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 20px;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 20px;
}

button.sc-bdvvtL {
  color: #fea758 !important;
  background: none;
  border: none;
  box-shadow: none !important;
}
.jRFJkP:hover {
  color: #fea758 !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}
.jRFJkP:focus {
  color: #fea758 !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}
.jRFJkP:hover:enabled,
.jRFJkP:focus:enabled {
  color: #fea758;
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}
button.sc-gKclnd {
  display: none;
}
/* .jRFJkP {
  background: none;
}
.jRFJkP:hover {
  background: none;
} */
/* header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
} */

.nav-area {
  display: flex;
  align-items: center;

  /* max-width: 1200px; */
  /* margin: 0 auto; */
  padding: 0 20px;
  background: #0f2c33;
  /* height: 9vh; */
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: sticky;
  font-size: 14px;
  font-family: "Arial";
  font-weight: bold;
  color: #f2f2f2;
}

.menu-dropdown {
  display: flex;
  list-style: none;
  border-bottom: 1px solid white;
}
.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  font-family: "Arial";
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

/* .menu-items button:hover {
  background-color: #ff7300;
} */
.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

/* .menu-items button:hover {
  border-bottom: 3px solid #FF7300;
  transition: all 0.2s ease-out;

} */

/* .menu-items button::before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid #FF7300;
  transform: scaleX(0);
  transform-origin: top left;
  transition: all 0.3s ease-out;
}
.menu-items button:hover::before {
  transform: scaleX(1);

} */
/* .menu-items a:hover,
.menu-items button:hover {
  background-color: #ff7300;
  border-radius: 5px; */
/* border-Bottom: "5px solid #FF7300", */
/* height "40%", */
/* } */

ul.menus li {
  background: "none";
}

ul.dropdown li:hover:not(:first-child, :last-child) {
  background: #ff7300;
}
ul.dropdown li:first-child:hover {
  /* border-top:20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #ff7300;
}
ul.dropdown li:last-child:hover {
  /* border-top:20px; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #ff7300;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em;
  border-left: 0.32em;

  /* border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent; */
}

.dropdown {
  position: absolute;
  top: 2.6vw;
  right: 20px !important;
  left: -28px;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  /* padding: 0.5rem 0; */
  list-style: none;
  background-color: #231f20;
  border-radius: 1.4rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  /* position: absolute; */
  left: 100%;
  top: -1px;
}

ul.dropdown :not(:last-child) {
  border-bottom: 1px solid white;
}
