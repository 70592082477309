.navbar {
  height: 12vh !important;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 29px;
  padding-right: 12px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
  padding: " 0  2px 0 2px";
}

.navbar.active {
  background: #0f2c33;

}

/* .menu-items:hover {
  border-bottom: 3px solid #FF7300;
  transition: all 0.2s ease-out;

} */

/* .menu-items::before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid #FF7300;
  transform: scaleX(0);
  transform-origin: top left;
  transition: all 0.3s ease-out;
}
.menu-items:hover::before {
  transform: scaleX(1);

} */

/* .menu-items:hover {
  border-bottom: 3px solid #FF7300;
  transition: all 0.2s ease-out;

} */

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 6rem;
}

.nav-items {
  display: flex;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: flex-end;
  margin-right: 1.4rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 10vh;
}

.nav-item a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-family: "Arial";
  font-weight: bolder;
  /* margin-right: 2rem; */
  padding: 9px;
  border-radius: 5px;
}

.nav-item a:hover {
  border-bottom: 14px;
  background: #ff7300;
  border-radius: 5px;
}
