.rec.rec-arrow {
  /* background-color: #ff7300; */
  color: white;
  background-color: #0f2c33;
  /* width: 10%;
  height: 14%; */
}
/* .rec.rec-arrow_active {
  /* background-color: #ff7300; */
  /* color: white; */
  /* background-color: #0f2c33; */

.rec.rec-arrow:hover {
 /* background-color: #ff7300; */
 background-color: #0f2c33;
 color: grey;
}

.rec.rec-dot {
  /* background-color: #ff7300; */
  box-shadow: white !important;
  background-color: #0f2c33;
  width:0.5rem;
  height:0.5rem;
}
.rec.rec-dot_active {
  /* background-color: #ff7300; */
  box-shadow: white !important;
  background-color: #ff7300;
}
