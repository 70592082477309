.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
.blogItem-wrap {
  display: flex;
  flex-direction: column;
  /* border: '1px solid red', */
}

.blogItem-cover {
  width: 100%;
  height: 125px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0.5rem;
}

.blogItem-wrap h3 {
  margin: 0 0 0.5rem 0;
  flex: 1;
  font-family: "Open Sans";
}

.blogItem-desc::after {
  content: "";
  position: "absolute";
  bottom: 0;
  right: 0;
  height: "21px";
  width: "75%";
  background: "linear-gradient(90deg,transparent,white)";
}

.blogItem-desc::before {
  position: absolute;
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: #ff7300;
  font-family: "open sans";
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
}

.blogItem-author p {
  font-size: 1rem;
  color: #8f8d8d;
  font-style: italic;
  font-weight: 300;
  font-family: "Open Sans";
}

.results-image {
  width: 100%;
  height: 150px;
  object-fit: fill;
  /* margin: 1rem 0.5rem 1rem 0.5rem; */
}
